import React, { useState } from "react";
import "../ServicesPage/rootcannal.css";

import icon from "../Image/Vector.png";
import icon2 from "../Image/Vector2.png";
import icon3 from "../Image/Vector3.png";
import icon4 from "../Image/Vector4.png";

export default function PreventiveCare() {
  // function onAccor() {
  //   const accordion = document.getElementsByClassName("contentBx");
  //   // for (var i = 0; i < accordion.length; i++) {
  //   // accordion.addEventListener("click", function () {
  //   //   this.classList.toggle("active");
  //   // });
  //   // // }
  //   accordion.classList.toggle("active");
  // }
  const [isActive, setActive] = useState("false");
  const [isActive1, setActive1] = useState("false");
  const [isActive2, setActive2] = useState("false");
  const [isActive3, setActive3] = useState("false");

  const toggleClass = () => {
    setActive(!isActive);
  };
  const toggleClass1 = () => {
    setActive1(!isActive1);
  };
  const toggleClass2 = () => {
    setActive2(!isActive2);
  };
  const toggleClass3 = () => {
    setActive3(!isActive3);
  };
  return (
    <>
      <div className="aboutHeader">
        <div className="aboutbg">
          <h1> Preventive Dental Care in Mumbai</h1>
          <button
            className="schedule"
            onClick={() =>
              window.open("https://api.whatsapp.com/send?phone=919892227839")
            }
          >
            Scedule Your Visit &nbsp; <i class="fab fa-whatsapp"></i>
          </button>
        </div>
      </div>
      <div className="process">
        <h2>
          What To expect From Your Preventive Care Appointment at Bhatt Dental
        </h2>
      </div>
      <div className="main-process">
        <div className="process-cont">
          {/* <div className="cont"> */}
          <ul className="icon-cont">
            <li className="icon-img">
              <img src={icon} className="icon" />
            </li>
            <li className="cont">
              your appointment will last about an hour and includes… Gum disease
              evaluation and early cavity detection Intraoral photography to
              show areas of concerns.
            </li>
          </ul>
          <ul className="icon-cont">
            <li className="icon-img">
              <img src={icon2} className="icon" />
            </li>
            <li className="cont">Gentle teeth cleaning to remove tartar.</li>
          </ul>
        </div>
        <div className="process-cont1">
          <ul className="icon-cont">
            <li className="icon-img">
              <img src={icon3} className="icon" />
            </li>
            <li className="cont1">
              Intraoral Radiographs for areas of Concern.
            </li>
          </ul>
          <ul className="icon-cont">
            <li className="icon-img">
              <img src={icon4} className="icon" />
            </li>
            <li className="cont1">
              Scheduling Recall Appointment for your next Preventive care visit
              & if required an appointment for addressing any areas of concern  
            </li>
          </ul>
        </div>
      </div>
      <div className="process-btn">
        <button
          onClick={() =>
            window.open("https://api.whatsapp.com/send?phone=919892227839")
          }
        >
          Appointment&nbsp; <i class="fab fa-whatsapp"></i>
        </button>
      </div>
      <div className="process">
        <h2>
          Dr. Bhatt answers your most common questions about Preventive Care
        </h2>
      </div>
      <div className="accordion">
        <div className={isActive ? "contentBx" : "contentBx active"}>
          <div className="label" onClick={toggleClass}>
            I have No pain or Any dental Problems do I still need Preventive
            Care?
          </div>
          <div className="content">
            <p>
              Absolutely, Most Dental Problems have no signs or symptoms when
              they first develop. Regular Preventive care not only helps to keep
              teeth and gums healthy by removing tartar but also helps in early
              detection and mitigation of dental problems. The Old Adage of “
              Prevention is Better Than Cure “ holds true with your dental
              health as well.
            </p>
          </div>
        </div>
        <div
          className={isActive1 ? "contentBx" : "contentBx active"}
          // onClick={() => {
          //   onAccor();
          // }}
        >
          <div className="label" onClick={toggleClass1}>
            What to know about Dental Sealants?
          </div>
          <div className="content">
            <p>
              Dental sealants prevent decay by forming are a thin, protective
              coating that easily adheres to the chewing surface of your back
              teeth.  proper care, sealants can be retained in the mouth for
              several years and can be easily replaced if required It Helps You
              Avoid Cavities, gives Long-lasting Protection, It is Especially
              Great for Children School-age kids without sealants have 3 times
              more cavities than those with sealants.
            </p>
          </div>
        </div>
        <div className={isActive2 ? "contentBx" : "contentBx active"}>
          <div className="label" onClick={toggleClass2}>
            What to Know About Sports Guard / Mouth Guard?
          </div>
          <div className="content">
            <p>
              Active Sports Injury are the leading cause for tooth loss in
              Children after Dental Decay!! Sports mouth guards are thick
              plastic devices that fit over the teeth like a dental tray formed
              to the teeth, gums, and mouth. ...Sports Mouth guards help save
              teeth and protect the jaw bone by helping to cushion blows to the
              face. They also help protect the soft tissues of the cheeks,
              tongue, and lips.
            </p>
          </div>
        </div>
        {/* <div className={isActive3 ? "contentBx" : "contentBx active"}>
          <div className="label" onClick={toggleClass3}>
            What Are the Options for replacing extracted tooth?
          </div>
          <div className="content">
            <p>
              There are many options like Dental Implants Dental Bridges or even
              Partial Dentures to replace missing teeth. Our office will explain
              you all your options to help you decide what is best for you.
            </p>
          </div>
        </div> */}
      </div>
    </>
  );
}
