import React from "react";
import { Link } from "react-scroll";
export function CustomScroll(props) {
  return (
    <>
      <li className="nav-item">
        <Link to="foooter-cont" className="nav-links">
          <a> {props.Name}</a>
        </Link>
      </li>
    </>
  );
}
