import React, { useState } from "react";
import { Button } from "./Button";
import { Link as A } from "react-scroll";
import { Link } from "react-router-dom";
import "./Navbar.css";
import logo from "../../Image/Bhatt-Dental.png";
// import Dropdown from "./Dropdown";
import { CustomScroll } from "./customScrol";
import Alert from "../Alert/alert";

function Navbar() {
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };
  const openNewPage = (url) => {
    const newWindow = window.open(url, "_self", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <>
      <Alert />

      <nav className="navbar">
        <Link to="/" onClick={closeMobileMenu}>
          <img className="logo-img" src={logo} />
        </Link>
        <div className="menu-icon" onClick={handleClick}>
          <i className={click ? "fas fa-times" : "fas fa-bars"} />
        </div>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <Link to="/aboutus" className="nav-links" onClick={closeMobileMenu}>
              <a
              //   onClick={() => {
              //     openNewPage("/aboutus");
              //   }}
              >
                About Us
              </a>
            </Link>
          </li>
          <li className="nav-item">
            <A to="service" className="nav-links" onClick={closeMobileMenu}>
              <a>Service</a>
            </A>
          </li>

          <CustomScroll Name="Contact" />
          {/* <li className="nav-item nav-item1"> */}
          <li className="nav-item">
            <Link
              to="foooter-cont"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              <Button />
            </Link>
          </li>
          {/* </li> */}
        </ul>
      </nav>
    </>
  );
}

export default Navbar;
