import React, { useState } from "react";
import { Link } from "react-router-dom";
import crossIcon from "../../Image/x-lg.png";
import { CustomScroll } from "../Alert/custom";
import "./alert.css";

function AlertDismissible() {
  const [isActive, setActive] = useState("false");
  const toggleClass = () => {
    setActive(!isActive);
  };
  function alert1() {
    // document.getElementById("alert").className[0] = "hide";
    document.getElementsByClassName("show")[0].innerHTML = "";
  }
  return (
    <div id="alert4" className={isActive ? "show" : "hide"}>
      <img onClick={toggleClass} src={crossIcon} />
      <p>
        Are You Worried About the  Dental Treatment Costs? Know More
        About Our{" "}
        <Link to="./plan">
          <strong className="strng">Save My Smile Plans</strong>
        </Link>{" "}
        or{" "}
        <strong>
          <CustomScroll name="Contact Us" />
        </strong>
        .
      </p>
    </div>
  );
}

export default AlertDismissible;
