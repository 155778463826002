import React, { useState } from "react";
import "../ServicesPage/rootcannal.css";

import icon from "../Image/Vector.png";
import icon2 from "../Image/Vector2.png";
import icon3 from "../Image/Vector3.png";
import icon4 from "../Image/Vector4.png";

export default function Rootcannal() {
  // function onAccor() {
  //   const accordion = document.getElementsByClassName("contentBx");
  //   // for (var i = 0; i < accordion.length; i++) {
  //   // accordion.addEventListener("click", function () {
  //   //   this.classList.toggle("active");
  //   // });
  //   // // }
  //   accordion.classList.toggle("active");
  // }
  const [isActive, setActive] = useState("false");
  const [isActive1, setActive1] = useState("false");
  const [isActive2, setActive2] = useState("false");
  const [isActive3, setActive3] = useState("false");

  const toggleClass = () => {
    setActive(!isActive);
  };
  const toggleClass1 = () => {
    setActive1(!isActive1);
  };
  const toggleClass2 = () => {
    setActive2(!isActive2);
  };
  const toggleClass3 = () => {
    setActive3(!isActive3);
  };
  return (
    <>
      <div className="aboutHeader">
        <div className="aboutbg">
          <h1> Dental Fillings in Mumbai</h1>
          <button
            className="schedule"
            onClick={() =>
              window.open("https://api.whatsapp.com/send?phone=919892227839")
            }
          >
            Scedule Your Visit &nbsp; <i class="fab fa-whatsapp"></i>
          </button>
        </div>
      </div>
      <div className="process">
        <h2>What to expect during Fillings at Bhatt Dental</h2>
      </div>
      <div className="main-process">
        <div className="process-cont">
          {/* <div className="cont"> */}
          <ul className="icon-cont">
            <li className="icon-img">
              <img src={icon} className="icon" />
            </li>
            <li className="cont">Dr. Bhatt will gently numb your mouth.</li>
          </ul>
          <ul className="icon-cont">
            <li className="icon-img">
              <img src={icon2} className="icon" />
            </li>
            <li className="cont">
              Relax while Dr. Bhatt carefully removes decay and prepares the
              surface of your tooth to receive filling.
            </li>
          </ul>
        </div>
        <div className="process-cont1">
          <ul className="icon-cont">
            <li className="icon-img">
              <img src={icon3} className="icon" />
            </li>
            <li className="cont1">
              Dr. Bhatt will prepare your filling, place it into the prepared
              tooth, and make sure it is secured.
            </li>
          </ul>
          <ul className="icon-cont">
            <li className="icon-img">
              <img src={icon4} className="icon" />
            </li>
            <li className="cont1">
              In just minutes, your cavity will be gone and your tooth restored
              with filling ready to function just like your natural tooth.
            </li>
          </ul>
        </div>
      </div>
      <div className="process-btn">
        <button
          onClick={() =>
            window.open("https://api.whatsapp.com/send?phone=919892227839")
          }
        >
          Appointment&nbsp; <i class="fab fa-whatsapp"></i>
        </button>
      </div>
      <div className="process">
        <h2>
          Dr. Bhatt answers your most common questions about dental Fillings
        </h2>
      </div>
      <div className="accordion">
        <div className={isActive ? "contentBx" : "contentBx active"}>
          <div className="label" onClick={toggleClass}>
            DO I need a Filling?
          </div>
          <div className="content">
            <p>
              You might have a cavity if… Teeth are sensitive to hot and cold,
              Pain when biting or chewing particularly sweet foods, Food getting
              stuck which you need to remove with tooth picks Soreness on one
              side of the mouth
            </p>
          </div>
        </div>
        <div
          className={isActive1 ? "contentBx" : "contentBx active"}
          // onClick={() => {
          //   onAccor();
          // }}
        >
          <div className="label" onClick={toggleClass1}>
            Can I have a Cavity if I have no pain or symptoms?
          </div>
          <div className="content">
            <p>
               Many cavities present no symptoms at all. They can only be
              diagnosed during your dental checkup.
            </p>
          </div>
        </div>
        <div className={isActive2 ? "contentBx" : "contentBx active"}>
          <div className="label" onClick={toggleClass2}>
            What can I Do to Prevent Dental Cavities?
          </div>
          <div className="content">
            <p>
              Simple steps like brushing twice daily flossing once daily
              significantly reduce risks of developing cavities. Also don’t
              forget the importance of periodic checkups. The key to treating a
              cavity is early detection.{" "}
              <span  onClick={() => {
                  window.open("/plan");
                }}>Check our Save My Smile Plans</span>, these are customized
              to offer preventive & Maintenance care you need to protect your
              smile and keep it healthy
            </p>
          </div>
        </div>
        <div className={isActive3 ? "contentBx" : "contentBx active"}>
          <div className="label" onClick={toggleClass3}>
            How much Do Dental Fillings Cost?
          </div>
          <div className="content">
            <p>
              The cost of Dental Filling is generally around Rs 1000-2500 per
              tooth
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
