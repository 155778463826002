import React, { useState } from "react";
import "../ServicesPage/rootcannal.css";

import icon from "../Image/Vector.png";
import icon2 from "../Image/Vector2.png";
import icon3 from "../Image/Vector3.png";
import icon4 from "../Image/Vector4.png";

export default function Rootcannal() {
  // function onAccor() {
  //   const accordion = document.getElementsByClassName("contentBx");
  //   // for (var i = 0; i < accordion.length; i++) {
  //   // accordion.addEventListener("click", function () {
  //   //   this.classList.toggle("active");
  //   // });
  //   // // }
  //   accordion.classList.toggle("active");
  // }
  const [isActive, setActive] = useState("false");
  const [isActive1, setActive1] = useState("false");
  const [isActive2, setActive2] = useState("false");
  const [isActive3, setActive3] = useState("false");

  const toggleClass = () => {
    setActive(!isActive);
  };
  const toggleClass1 = () => {
    setActive1(!isActive1);
  };
  const toggleClass2 = () => {
    setActive2(!isActive2);
  };
  const toggleClass3 = () => {
    setActive3(!isActive3);
  };
  return (
    <>
      <div className="aboutHeader">
        <div className="aboutbg">
          <h1>Root Canal Dentist in Mumbai</h1>
          <button
            className="schedule"
            onClick={() =>
              window.open("https://api.whatsapp.com/send?phone=919892227839")
            }
          >
            Scedule Your Visit &nbsp; <i class="fab fa-whatsapp"></i>
          </button>
        </div>
      </div>
      <div className="process">
        <h2>What to expect during a root canal at Bhatt Dental</h2>
      </div>
      <div className="main-process">
        <div className="process-cont">
          {/* <div className="cont"> */}
          <ul className="icon-cont">
            <li className="icon-img">
              <img src={icon} className="icon" />
            </li>
            <li className="cont">
              We will explain the procedure and make sure you’re comfortable and
              fully numb before getting started.
            </li>
          </ul>
          <ul className="icon-cont">
            <li className="icon-img">
              <img src={icon2} className="icon" />
            </li>
            <li className="cont">
              We will remove any sign of decay or infection. We Will then seal and
              fill the remaining tooth structure.
            </li>
          </ul>
        </div>
        <div className="process-cont1">
          <ul className="icon-cont">
            <li className="icon-img">
              <img src={icon3} className="icon" />
            </li>
            <li className="cont1">
              After you heal, we can plan for your crown, making a model of your
              mouth and sending it to a dental lab.
            </li>
          </ul>
          <ul className="icon-cont">
            <li className="icon-img">
              <img src={icon4} className="icon" />
            </li>
            <li className="cont1">
              When your crown is ready, we’ll invite you back to our office to
              bond your crown securely in place.
            </li>
          </ul>
        </div>
      </div>
      <div className="process-btn">
        <button
          onClick={() =>
            window.open("https://api.whatsapp.com/send?phone=919892227839")
          }
        >
          Appointment&nbsp; <i class="fab fa-whatsapp"></i>
        </button>
      </div>
      <div className="process">
        <h2>Dr. Bhatt answers your most common questions about root canals</h2>
      </div>
      <div className="accordion">
        <div className={isActive ? "contentBx" : "contentBx active"}>
          <div className="label" onClick={toggleClass}>
            What is Root-Canal?
          </div>
          <div className="content">
            <p>
              When a cavity is small, bacteria and decay are contained to the
              outermost layer of the tooth. As a cavity grows, the decay
              advances deeper into the tooth, leading to a very painful
              toothache and infection. A root canal removes decay from deep
              within the tooth, stops any infection, and relieves bad
              toothaches. By performing a root canal, We can help you keep your
              natural teeth & they function just as before While most root
              canals are the result of untreated decay, injuries and accidents
              can crack teeth, too. This allows bacteria to spread deep inside
              the tooth. So if you’ve broken a tooth in an accident or while
              eating, get it checked out.  The sooner the better!
            </p>
          </div>
        </div>
        <div
          className={isActive1 ? "contentBx" : "contentBx active"}
          // onClick={() => {
          //   onAccor();
          // }}
        >
          <div className="label" onClick={toggleClass1}>
            Does a Root Canal Hurt?
          </div>
          <div className="content">
            <p>
              Despite its reputation, the root canal procedure itself is not
              actually associated with any real pain or discomfort. Instead, it
              is the toothache that occurs in the lead up to a root canal that
              causes the real issue. Our office  offer exceptional care and
              reassurance and best the technology has on offer to help you glide
              through this procedure with ease!
            </p>
          </div>
        </div>
        <div className={isActive2 ? "contentBx" : "contentBx active"}>
          <div className="label" onClick={toggleClass2}>
            Are Root Canal Safe?
          </div>
          <div className="content">
            <p>
              Yes. Root canals are proven to stop infection and save teeth.
              Roughly 25 million root canals are performed every year. There is
              no scientific evidence supporting the connection between root
              canal-treated teeth and disease or problems elsewhere in the body.
            </p>
          </div>
        </div>
        <div className={isActive3 ? "contentBx" : "contentBx active"}>
          <div className="label" onClick={toggleClass3}>
            Are Root Canal Expensive ?
          </div>
          <div className="content">
            <p>
              Approximate cost of. A Root Canal varies from 4500-8000 Root
              canals help save the tooth and help you efficiently chew your food
              . Our office will help you decide what is best for you after
              examining you. You can also avil the Benefits of Our
              <span
                onClick={() => {
                  window.open("/plan");
                }}
              >
                {" "}
                "Save My Smile Plan"
              </span>{" "}
              initiative to avail the EMI benefits for your treatment .
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
