import React from "react";
import "../Plans/plan.css";
import { Link } from "react-scroll";
import icon from "../Image/dot.png";
// import planImage from "../Image/bhatt-plan.png";
import bajaj from "../Image/Bajaj.png";
import hdfc from "../Image/mosambee.png";
export default function plan() {
  const openNewPage = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  return (
    <>
      <div className="aboutHeader">
        <div className=" plan-h2">
          <h1>
            Are you worried about Dental Treatment Costs? Help Is Available
          </h1>
          <button
            className="schedule schedule1"
            onClick={() =>
              openNewPage("https://api.whatsapp.com/send?phone=919892227839")
            }
          >
            Scedule Your Visit&nbsp; <i class="fab fa-whatsapp"></i>
          </button>
        </div>
      </div>
      <div className="plan-display">
        <div className="plan-cont">
          <p>
            {" "}
            Dr.Bhatt understands that Treatment cost is something which most
            Patients are worried about and is second only to Fear of Pain while
            seeking Dental Care.
          </p>
        </div>
        <Link to="plan">
          <button className="schedule schedule2">View Plans</button>
        </Link>
      </div>
      <div className="savemyplan">
        <div className="save-h2">
          <h2>Save My Smile Plans</h2>
        </div>
        <div className="main-process">
          <div className="process-cont">
            {/* <div className="cont"> */}
            <ul className="icon-cont">
              <li
                className="icon-img1
              "
              >
                <img src={icon} className="icon" />
              </li>
              <li className="conth1" id="plan">
                EMI’s for Dental Treatment
              </li>
            </ul>
            <ul className="icon-cont">
              {/* <li className="icon-img1
              ">
                <img src={icon2} className="icon" />
              </li> */}
              <li className="cont3">
                To make it easy for patients to get the care they need without
                the fear of cost We have introduced easy payment plans for
                treatments needed.
                <br /> These Payment Plans With our Finance Partners Help our
                Patients get the Dental Treatment On Easy EMI’s
              </li>
            </ul>
          </div>
          <div className="process-cont">
            {/* <div className="cont"> */}
            <ul className="icon-cont">
              <li
                className="icon-img2
              "
              >
                <img src={icon} className="icon" />
              </li>
              <li className="conth1">SMILE Care Preventive Plan</li>
            </ul>
            <ul className="icon-cont">
              {/* <li className="icon-img1
              ">
                <img src={icon2} className="icon" />
              </li> */}
              <li className="cont4">
                This Is A Unique Initiative Where our enrolled Members for a
                small Annual Fee have access to Preventive & Maintenance
                Services with periodic recall and Checkups .
                <br /> Call Our Office to Understand how Smile Care Preventive
                Plans can help you.
              </li>
            </ul>
          </div>
        </div>
        <Link to="foooter-cont">
          <div className="process-btn process-btn1">
            <button>Contact Us</button>
          </div>
        </Link>
      </div>
      <div className="save-h2 brandh2">
        <h2>EMI Partner</h2>
      </div>
      <div className="brands">
        <div className="bajaj-div">
          <img className="bajaj" src={bajaj} />
        </div>
        <div
          className="hdfc-div
        "
        >
          <img src={hdfc} />
        </div>
      </div>
    </>
  );
}
