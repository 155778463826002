import React, { useState } from "react";
import "../ServicesPage/rootcannal.css";

import icon from "../Image/Vector.png";
import icon2 from "../Image/Vector2.png";
import icon3 from "../Image/Vector3.png";
import icon4 from "../Image/Vector4.png";

export default function Rootcannal() {
  // function onAccor() {
  //   const accordion = document.getElementsByClassName("contentBx");
  //   // for (var i = 0; i < accordion.length; i++) {
  //   // accordion.addEventListener("click", function () {
  //   //   this.classList.toggle("active");
  //   // });
  //   // // }
  //   accordion.classList.toggle("active");
  // }
  const [isActive, setActive] = useState("false");
  const [isActive1, setActive1] = useState("false");
  const [isActive2, setActive2] = useState("false");
  const [isActive3, setActive3] = useState("false");

  const toggleClass = () => {
    setActive(!isActive);
  };
  const toggleClass1 = () => {
    setActive1(!isActive1);
  };
  const toggleClass2 = () => {
    setActive2(!isActive2);
  };
  const toggleClass3 = () => {
    setActive3(!isActive3);
  };
  return (
    <>
      <div className="aboutHeader">
        <div className="aboutbg">
          <h1> Full Mouth Rehabs Dentist in Mumbai</h1>
          <button
            className="schedule"
            onClick={() =>
              window.open("https://api.whatsapp.com/send?phone=919892227839")
            }
          >
            Scedule Your Visit &nbsp; <i class="fab fa-whatsapp"></i>
          </button>
        </div>
      </div>
      <div className="process">
        <h2>What to expect during Full Mouth Rehabilitation at Bhatt Dental</h2>
      </div>
      <div className="main-process">
        <div className="process-cont">
          {/* <div className="cont"> */}
          <ul className="icon-cont">
            <li className="icon-img">
              <img src={icon} className="icon" />
            </li>
            <li className="cont">
              We want to meet you and learn a bit more about your health
              history, really digging in to find out what’s going on in your
              mouth.  Tell us a bit about what you want to achieve and what you
              want your new smile to look like!
            </li>
          </ul>
          <ul className="icon-cont">
            <li className="icon-img">
              <img src={icon2} className="icon" />
            </li>
            <li className="cont">
              This is a collaborative process, and we want you to feel in
              control of the outcome. Together we’ll find a plan that meets your
              health and smile needs and fits your lifestyle.
            </li>
          </ul>
        </div>
        <div className="process-cont1">
          <ul className="icon-cont">
            <li className="icon-img">
              <img src={icon3} className="icon" />
            </li>
            <li className="cont1">
              We’ll be there with you in this transformative Journey & make sure
              you’re doing okay & your treatment plan is followed as outlined
              and adjusted to your specific requirements We want you to feel
              confident about how your full mouth rehab is progressing. Most
              importantly, we’ll make sure you’re completely satisfied with the
              outcome.
            </li>
          </ul>
          <ul className="icon-cont">
            <li className="icon-img">
              <img src={icon4} className="icon" />
            </li>
            <li className="cont1">
              Post the completion of your rehab, We schedule periodic recalls to
              help maintain your oral health.
            </li>
          </ul>
        </div>
      </div>
      <div className="process-btn">
        <button
          onClick={() =>
            window.open("https://api.whatsapp.com/send?phone=919892227839")
          }
        >
          Appointment&nbsp; <i class="fab fa-whatsapp"></i>
        </button>
      </div>
      <div className="process">
        <h2>
          Dr. Bhatt answers your most common questions about Full Mouth
          Rehabilitation
        </h2>
      </div>
      <div className="accordion">
        <div className={isActive ? "contentBx" : "contentBx active"}>
          <div className="label" onClick={toggleClass}>
            What are various procedures for Full Mouth Rehabilitation?
          </div>
          <div className="content">
            <p>
              Our approach to full mouth rehabilitation is built on the belief
              that there’s never a one-size-fits-all answer. We offer truly
              customized solutions to make sure we meet your every need. Your
              treatment plan may include a variety of general, cosmetic, or
              surgical treatments based your requirements Like Implants, Crowns,
              Veneers, Dental Fillings, Root canals, Gum recession treatment,
              Gum disease treatment, Bone regeneration, Gum grafting
            </p>
          </div>
        </div>
        <div
          className={isActive1 ? "contentBx" : "contentBx active"}
          // onClick={() => {
          //   onAccor();
          // }}
        >
          <div className="label" onClick={toggleClass1}>
            What All Problems can be corrected in Full Mouth Rehabilitation?
          </div>
          <div className="content">
            <p>
              Problems like Multiple missing teeth, Uneven or Misaligned Teeth,
              Extensive decay and or broken teeth. Worn down of shortened teeth
              due to grinding. Dark Stained teeth , Gum Diseases are among the
              common problems for which a ptient would come to us for
              correction.
            </p>
          </div>
        </div>
        <div className={isActive2 ? "contentBx" : "contentBx active"}>
          <div className="label" onClick={toggleClass2}>
            Is the process Expensive and time consuming?
          </div>
          <div className="content">
            <p>
              The entire cost and time duration required is largely dependent on
              the nature of problem and the procedures required to rehabilitate
              the dentition. We after examining you and understanding your
              requirements will offer you various treatment options which can
              give the best value for your time and resources . You can also
              avail benefits of our “Save my Smile Plan “ to access financing
              options for your treatment.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
