import React from "react";
import "./profile.css";
import ProfileImg from "../../Image/vishal-bhatt.jpg";
import * as ReactBoostrap from "react-bootstrap";
import { Link } from "react-router-dom";

function Profile() {
  const openNewPage = (url) => {
    const newWindow = window.open(url, "_self", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  return (
    <>
      {/* <form>
        <script
          src="https://checkout.razorpay.com/v1/payment-button.js"
          data-payment_button_id="pl_HzalgPP4tU9wBr"
          async
        >
          {" "}
        </script>{" "}
      </form> */}
      <div className="profile-sec" id="profile">
        <div className="profile-img">
          <img src={ProfileImg} />
        </div>
        <div className="profile-cont">
          <h1>Dr. Vishal Bhatt</h1>
          <p className="p-cont">
            Dr. Bhatt is the Resident Dentist & Proprietor @ Bhatt Dental . He
            Graduated from Dental School in 2007. He Did his Dental Course from
            Yerla Dental College, Navi Mumbai & Internship From Prestigious Govt
            Dental College Mumbai.Since his graduation Dr. Bhatt has
            continuously strived to upgrade his skills with Various Continuing
            Dental Education Courses.
          </p>
          <p className="p1-cont">
            Dr. Bhatt believes in empowering patients with the Knowledge and
            Education so they can be empowered to take control of their Dental
            Health.
          </p>
          <Link to="/">
            <button
              className="appointment  about-btn"
              onClick={() => {
                openNewPage("./aboutus");
              }}
            >
              Know More
            </button>
          </Link>
        </div>
      </div>
    </>
  );
}
export default Profile;
