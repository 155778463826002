import React from "react";
import "../aboutHeader/header.css";
export default function aboutheader() {
  return (
    <>
      <div className="aboutHeader">
        <div className="aboutbg">
            <h1>Get to know the Bhatt-Dental Team</h1>
            <button className="schedule">Scedule Your Visit</button>
        </div>
      </div>
    </>
  );
}
