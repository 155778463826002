import "./App.css";
import Navbar from "./Home/Navbar/Navbar";
import HomePage from "./Home/Home";
import Footer from "./Home/footer/Footer";
import Term from "./Terms & Condtion/terms";
import About from "./About US/about";
import Rootcannal from "./ServicesPage/rootcannal";
import Crowns from "./ServicesPage/crowns";
import DentalFilling from "./ServicesPage/dentalfilling";
import SmileDesign from "./ServicesPage/smiledesign";
import TeethRemoval from "./ServicesPage/teethremove";
import PreventiveCare from "./ServicesPage/preventivecare";
import Implants from "./ServicesPage/implants";
import FullMouth from "./ServicesPage/fullmouth";
import Emergency from "./ServicesPage/emergency";
import Plan from "./Plans/plan";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Switch>
          <Route path="/" exact component={HomePage} />
          <Route path="/term" exact component={Term} />
          <Route path="/aboutus" exact component={About} />
          <Route path="/service/rootcannal" exact component={Rootcannal} />
          <Route path="/service/crowns" exact component={Crowns} />
          <Route
            path="/service/dentalfilling"
            exact
            component={DentalFilling}
          />
          <Route path="/service/smiledesing" exact component={SmileDesign} />
          <Route path="/service/teethremoval" exact component={TeethRemoval} />
          <Route
            path="/service/preventine-care"
            exact
            component={PreventiveCare}
          />
          <Route path="/service/fullmouthrehabs" exact component={FullMouth} />
          <Route path="/service/implants&dentures" exact component={Implants} />

          <Route path="/plan" exact component={Plan} />
          <Route path="/service/emergency" exact component={Emergency} />
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}
export default App;
