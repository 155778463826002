import React, { useState } from "react";
import { Link } from "react-scroll";
import "../ServicesPage/rootcannal.css";

import icon from "../Image/Vector.png";
import icon2 from "../Image/Vector2.png";
import icon3 from "../Image/Vector3.png";
import icon4 from "../Image/Vector4.png";

export default function Rootcannal() {
  // function onAccor() {
  //   const accordion = document.getElementsByClassName("contentBx");
  //   // for (var i = 0; i < accordion.length; i++) {
  //   // accordion.addEventListener("click", function () {
  //   //   this.classList.toggle("active");
  //   // });
  //   // // }
  //   accordion.classList.toggle("active");
  // }
  const [isActive, setActive] = useState("false");
  const [isActive1, setActive1] = useState("false");
  const [isActive2, setActive2] = useState("false");
  const [isActive3, setActive3] = useState("false");

  const toggleClass = () => {
    setActive(!isActive);
  };
  const toggleClass1 = () => {
    setActive1(!isActive1);
  };
  const toggleClass2 = () => {
    setActive2(!isActive2);
  };
  const toggleClass3 = () => {
    setActive3(!isActive3);
  };

  return (
    <>
      <div className="aboutHeader">
        <div className="aboutbg">
          <h1> Crowns & Bridges Dentist in Mumbai</h1>
          <button
            className="schedule"
            onClick={() =>
              window.open("https://api.whatsapp.com/send?phone=919892227839")
            }
          >
            Scedule Your Visit &nbsp; <i class="fab fa-whatsapp"></i>
          </button>
        </div>
      </div>
      <div className="process">
        <h2>What to expect during dental Crowns & Bridges Bhatt Dental</h2>
      </div>
      <div className="main-process">
        <div className="process-cont">
          {/* <div className="cont"> */}
          <ul className="icon-cont">
            <li className="icon-img">
              <img src={icon} className="icon" />
            </li>
            <li className="cont">
              Dr. Bhatt will gently numb your mouth and then we carefully
              prepare the surface of your tooth, making space for the crown /
              Bridge.
            </li>
          </ul>
          <ul className="icon-cont">
            <li className="icon-img">
              <img src={icon2} className="icon" />
            </li>
            <li className="cont">
              Next, we Make accurate Impressions , and use it as a guide to
              create your crown/Bridge
            </li>
          </ul>
        </div>
        <div className="process-cont1">
          <ul className="icon-cont">
            <li className="icon-img">
              <img src={icon3} className="icon" />
            </li>
            <li className="cont1">
              We’ll fit you with a temporary crown, allowing you to chew
              normally while your permanent crown / bridge is made.
            </li>
          </ul>
          <ul className="icon-cont">
            <li className="icon-img">
              <img src={icon4} className="icon" />
            </li>
            <li className="cont1">
              When your crown/Bridge is ready, we’ll invite you back to our
              office so we can bond your crown securely in place. You’ll love
              the result!
            </li>
          </ul>
        </div>
      </div>
      <div className="process-btn">
        <button
          onClick={() =>
            window.open("https://api.whatsapp.com/send?phone=919892227839")
          }
        >
          Appointment&nbsp; <i class="fab fa-whatsapp"></i>
        </button>
      </div>
      <div className="process">
        <h2>
          Dr. Bhatt answers your most common questions about dental Crowns &
          Brodges
        </h2>
      </div>
      <div className="accordion">
        <div className={isActive ? "contentBx" : "contentBx active"}>
          <div className="label" onClick={toggleClass}>
            What Are Dental Bridges?
          </div>
          <div className="content">
            <p>
              As the name suggests, this treatment “bridges” the gap left in
              your mouth by a missing tooth. With this procedure, the bridge is
              held in place by the healthy teeth adjacent to the gap in your
              smile.
            </p>
          </div>
        </div>
        <div
          className={isActive1 ? "contentBx" : "contentBx active"}
          // onClick={() => {
          //   onAccor();
          // }}
        >
          <div className="label" onClick={toggleClass1}>
            How do I care for my Crowns /Onlays/Bridges?
          </div>
          <div className="content">
            <p>
              Regular Brushing and flossing just like you do for all your other
              teeth . Its always advisible to visit Our office at least twice
              each year for checkups and teeth cleanings. We’ll evaluate your
              restorations during these appointments, checking for signs of wear
              or damage.
            </p>
          </div>
        </div>
        <div className={isActive2 ? "contentBx" : "contentBx active"}>
          <div className="label" onClick={toggleClass2}>
            What Are Onlays Or Crownlays can I get them over Crowns ?
          </div>
          <div className="content">
            <p>
              Onlays Crownlays are newer more conservative options to restore a
              tooth. They are made of Highly Esthetic Porcelain which adheres to
              the teeth with a strong adhesive and function just normal teeth.
              They are a good alternative to full crowns.
            <span>
              <Link to="foooter-cont">
            Contact us </Link> </span>to help you decide what is best for you .
            </p>
          </div>
        </div>
        <div className={isActive3 ? "contentBx" : "contentBx active"}>
          <div className="label" onClick={toggleClass3}>
            What is the Cost for Dental Crowns Bridges & Onlays?
          </div>
          <div className="content">
            <p>
              The crowns depending on the Material used & the technique used to
              fabricate the price can vary. A Metal Crown starts from Rs 3500
              while the Porcelain Fused to Metal Crowns starts from Rs 5500.
              Metal free crowns & Onlays vary in the price range of Rs 10000 to
              Rs 15000.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
