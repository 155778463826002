import React, { useState } from "react";
import "../ServicesPage/rootcannal.css";

import icon from "../Image/Vector.png";
import icon2 from "../Image/Vector2.png";
import icon3 from "../Image/Vector3.png";
import icon4 from "../Image/Vector4.png";

export default function Rootcannal() {
  // function onAccor() {
  //   const accordion = document.getElementsByClassName("contentBx");
  //   // for (var i = 0; i < accordion.length; i++) {
  //   // accordion.addEventListener("click", function () {
  //   //   this.classList.toggle("active");
  //   // });
  //   // // }
  //   accordion.classList.toggle("active");
  // }
  const [isActive, setActive] = useState("false");
  const [isActive1, setActive1] = useState("false");
  const [isActive2, setActive2] = useState("false");
  const [isActive3, setActive3] = useState("false");

  const toggleClass = () => {
    setActive(!isActive);
  };
  const toggleClass1 = () => {
    setActive1(!isActive1);
  };
  const toggleClass2 = () => {
    setActive2(!isActive2);
  };
  const toggleClass3 = () => {
    setActive3(!isActive3);
  };
  return (
    <>
      <div className="aboutHeader">
        <div className="aboutbg">
          <h1 className="smileH1">
            {" "}
            Smile Design Esthetic Dentistry in Mumbai
          </h1>
          <button className="schedule"
          onClick={() =>
            window.open("https://api.whatsapp.com/send?phone=919892227839")
          }
          >
            Scedule Your Visit &nbsp; <i class="fab fa-whatsapp"></i>
          </button>
        </div>
      </div>
      <div className="process">
        <h2>What to expect during a Smile Design Esthetic Dentistry at Bhatt Dental</h2>
      </div>
      <div className="main-process">
        <div className="process-cont">
          {/* <div className="cont"> */}
          <ul className="icon-cont">
            <li className="icon-img">
              <img src={icon} className="icon" />
            </li>
            <li className="cont">
              Our office after a detailed consultation with you to understand
              your expectations
            </li>
          </ul>
          <ul className="icon-cont">
            <li className="icon-img">
              <img src={icon2} className="icon" />
            </li>
            <li className="cont">
              Once you are fully satisfied with your trial and after making
              necessary changes requested by you we prepare the teeth to receive
              veneers.
            </li>
          </ul>
        </div>
        <div className="process-cont1">
          <ul className="icon-cont">
            <li className="icon-img">
              <img src={icon3} className="icon" />
            </li>
            <li className="cont1">
              This is the final step where the Veneers made by lab are checked &
              if it matches our treatment expectations we bond them to your
              teeth using special cements
            </li>
          </ul>
          <ul className="icon-cont">
            <li className="icon-img">
              <img src={icon4} className="icon" />
            </li>
            <li className="cont1">
              This is the final step where the Veneers made by lab are checked &
              if it matches our treatment expectations we bond them to your
              teeth using special cements.
            </li>
          </ul>
        </div>
      </div>
      <div className="process-btn">
        <button
        onClick={() =>
            window.open("https://api.whatsapp.com/send?phone=919892227839")
          }
        >
          Appointment&nbsp; <i class="fab fa-whatsapp"></i>
        </button>
      </div>
      <div className="process">
        <h2>Dr. Bhatt answers your most common questions about Smile Design Esthetic Dentistry</h2>
      </div>
      <div className="accordion">
        <div className={isActive ? "contentBx" : "contentBx active"}>
          <div className="label" onClick={toggleClass}>
           Is Smile Designing Expensive & Time Consuming?
          </div>
          <div className="content">
            <p>
             The cost & time varies based on your requirements and procedures required to meet your expectations. Contact us to schedule an appointment where we can understand your needs and design customized treatment solutions for you.
            </p>
          </div>
        </div>
        <div
          className={isActive1 ? "contentBx" : "contentBx active"}
          // onClick={() => {
          //   onAccor();
          // }}
        >
          <div className="label" onClick={toggleClass1}>
           What are my options while enhancing the Appearances of my teeth?
          </div>
          <div className="content">
            <p>
           <b> Procelain Veeners :-</b> one of the quickest, most dramatic ways to improve your smile <br/>
<b>Composite Restorations :-</b> to correct  the minor aberrations in tooth shape and sizes like small gaps between teeth                                     ( Diastema Closures ).<br/>
<b>Teeth Whitening:- </b> Best way to Brighten the teeth and make them appear Whiter
            </p>
          </div>
        </div>
        <div className={isActive2 ? "contentBx" : "contentBx active"}>
          <div className="label" onClick={toggleClass2}>
            What can be done about blackish appearances of my Gums ?
          </div>
          <div className="content">
            <p>
             Gum Pigmentation is a very common problem which makes your gums appear blackish while you smile . A simple one sitting Gum Depigmentation procedure can significantly change the appearance and make your smile more pleasing to look.
            </p>
          </div>
        </div>
        <div className={isActive3 ? "contentBx" : "contentBx active"}>
          <div className="label" onClick={toggleClass3}>
           What are Veneers?
          </div>
          <div className="content">
            <p>
             Veneers are thin, durable, and custom-made porcelain shells for your teeth. 
This treatment effectively covers up and protects your smile from stains, cracks, and chips.

Our office takes an artistic approach to dental veneer treatments. We make sure your finished smile looks as natural and seamless as possible.
            </p>
          </div>
        </div>
            <div className={isActive3 ? "contentBx" : "contentBx active"}>
          <div className="label" onClick={toggleClass3}>
           I have a very Gummy Smile and my Teeth Look very Small when I Smile Can it be corrected?
          </div>
          <div className="content">
            <p>
            Yes Gummy smile or inadequate teeth visibility are common reasons for which a Smile Design procedure is recommended. Our office after clinical examination can help you in enhancing the appearance of your Smile.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
