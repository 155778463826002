import React, { useState } from "react";
import "../ServicesPage/rootcannal.css";

import emergencyImg from "../Image/emergency.png";

import icon from "../Image/Vector.png";
import icon2 from "../Image/Vector2.png";
import icon3 from "../Image/Vector3.png";
import icon4 from "../Image/Vector4.png";

export default function Rootcannal() {
  // function onAccor() {
  //   const accordion = document.getElementsByClassName("contentBx");
  //   // for (var i = 0; i < accordion.length; i++) {
  //   // accordion.addEventListener("click", function () {
  //   //   this.classList.toggle("active");
  //   // });
  //   // // }
  //   accordion.classList.toggle("active");
  // }
  const [isActive, setActive] = useState("false");
  const [isActive1, setActive1] = useState("false");
  const [isActive2, setActive2] = useState("false");
  const [isActive3, setActive3] = useState("false");

  const toggleClass = () => {
    setActive(!isActive);
  };
  const toggleClass1 = () => {
    setActive1(!isActive1);
  };
  const toggleClass2 = () => {
    setActive2(!isActive2);
  };
  const toggleClass3 = () => {
    setActive3(!isActive3);
  };
  return (
    <>
      <div className="aboutHeader">
        <div className="aboutbg">
          <h1>Emergency Dental Care</h1>
          <button
            className="schedule"
            onClick={() =>
              window.open("https://api.whatsapp.com/send?phone=919892227839")
            }
          >
            Scedule Your Visit &nbsp; <i class="fab fa-whatsapp"></i>
          </button>
        </div>
      </div>
      <div className="profile-sec" id="profile">
        <div className="profile-img">
          <img src={emergencyImg} />
        </div>
        <div className="profile-cont">
          <h2>Get help for toothaches, broken or Knocked out teeth & more…</h2>
          <p className="p-cont">
            We understand that dental emergencies can be stressful. And when
            they happen, we’re here to help. Call us at 
            <a href="tel:9892227839">+91-9892227839</a> and we’ll get you
            scheduled for immediate care in our office.
          </p>
          <p className="p1-cont">
            Dr. Bhatt believes in empowering patients with the Knowledge and
            Education so they can be empowered to take control of their Dental
            Health.
          </p>
          {/* <div className="process-btn">
            <button>
              Appointment&nbsp; <i class="fab fa-whatsapp"></i>
            </button>
          </div> */}
          {/* <Link to="/"> */}
          <button
            className="appointment  about-btn"
            onClick={() =>
              window.open("https://api.whatsapp.com/send?phone=919892227839")
            }
          >
            Appointment
          </button>
          {/* </Link> */}
        </div>
      </div>

      <div className="process">
        <h2>Good To Know...In Case Of Emergency</h2>
      </div>
      <div className="accordion">
        <div className={isActive ? "contentBx" : "contentBx active"}>
          <div className="label" onClick={toggleClass}>
            What should I do if a tooth is loose or knocked off after accident ?
          </div>
          <div className="content">
            <p>
              If your tooth is still in one piece, pick it up by the crown
              (don’t touch the root) and gently rinse it off under running
              water. Carefully place the tooth back into the socket and call us
              right away to let us know what happened. The sooner you seek
              treatment, the better your chances that we can save your tooth.
            </p>
          </div>
        </div>
        <div
          className={isActive1 ? "contentBx" : "contentBx active"}
          // onClick={() => {
          //   onAccor();
          // }}
        >
          <div className="label" onClick={toggleClass1}>
            What are the signs of an infected tooth?
          </div>
          <div className="content">
            <p>
              A few common symptoms of a tooth infection include:  <br />
              Constant toothache
              <br />
              Lingering tooth sensitivity <br />
              Sore and sensitive jawbone
            </p>
          </div>
        </div>
        <div className={isActive2 ? "contentBx" : "contentBx active"}>
          <div className="label" onClick={toggleClass2}>
            Will a toothache go away on its own?
          </div>
          <div className="content">
            <p>
              It’s possible your toothache may lessen or disappear, but this
              doesn’t mean the problem went away. In fact, the longer you wait,
              the more damage (and discomfort) you risk. Always seek help for a
              toothache (and avoid a bigger issue) right away.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
