import React from "react";
import "./header.css";
import { Link } from "react-router-dom";
const openNewPage = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};
function header(props) {
  return (
    <>
      <div className="main-cont">
        <div className="bg-img">
          <h1>Personalised Care Guaranteed To Bring A Smile!</h1>
          <h4>
            Our Motto is to Help And Empower Our Patients to take control of
            their Dental Health & Achieve the Smile They Always Wanted.
          </h4>
          <Link to="/">
            <button
              className="appointment"
              onClick={() =>
                openNewPage("https://api.whatsapp.com/send?phone=919892227839")
              }
            >
              Appointment&nbsp; <i class="fab fa-whatsapp"></i>
            </button>
          </Link>
        </div>
      </div>
    </>
  );
}

export default header;
