import React, { useState } from "react";
import "../ServicesPage/rootcannal.css";

import icon from "../Image/Vector.png";
import icon2 from "../Image/Vector2.png";
import icon3 from "../Image/Vector3.png";
import icon4 from "../Image/Vector4.png";

export default function Rootcannal() {
  // function onAccor() {
  //   const accordion = document.getElementsByClassName("contentBx");
  //   // for (var i = 0; i < accordion.length; i++) {
  //   // accordion.addEventListener("click", function () {
  //   //   this.classList.toggle("active");
  //   // });
  //   // // }
  //   accordion.classList.toggle("active");
  // }
  const [isActive, setActive] = useState("false");
  const [isActive1, setActive1] = useState("false");
  const [isActive2, setActive2] = useState("false");
  const [isActive3, setActive3] = useState("false");

  const toggleClass = () => {
    setActive(!isActive);
  };
  const toggleClass1 = () => {
    setActive1(!isActive1);
  };
  const toggleClass2 = () => {
    setActive2(!isActive2);
  };
  const toggleClass3 = () => {
    setActive3(!isActive3);
  };

  return (
    <>
      <div className="aboutHeader">
        <div className="aboutbg">
          <h1> Implant or Denture Dentist in Mumbai</h1>
          <button
            className="schedule"
            onClick={() =>
              window.open("https://api.whatsapp.com/send?phone=919892227839")
            }
          >
            Scedule Your Visit &nbsp; <i class="fab fa-whatsapp"></i>
          </button>
        </div>
      </div>
      <div className="process">
        <h2>
          What to Expect in your Implant or Denture Appointment at Bhatt Dental
        </h2>
      </div>
      <div className="main-process">
        <div className="process-cont">
          {/* <div className="cont"> */}
          <ul className="icon-cont">
            <li className="icon-img">
              <img src={icon} className="icon" />
            </li>
            <li className="cont">
              Your Treatment will be carefully planned out by taking Xrays & 3D
              imaging to visualize and evaluate the current status of your
              existing teeth & bone condition and ensure appropriate denture
              design & /or proper implant placement where indicated
            </li>
          </ul>
          <ul className="icon-cont">
            <li className="icon-img">
              <img src={icon2} className="icon" />
            </li>
            <li className="cont">
              Your Implant will be expertly placed, ensuring your total comfort
              throughout the procedure. The implant will be given time to
              integrate with your jaw bone. In case of Dentures we will prepare
              your existing teeth and residual gums to be best suited to receive
              denture .
            </li>
          </ul>
        </div>
        <div className="process-cont1">
          <ul className="icon-cont">
            <li className="icon-img">
              <img src={icon3} className="icon" />
            </li>
            <li className="cont1">
              Once the implant has integrated, we’ll create and bond the
              recommended restoration, such as a crown, bridge, or implant
              dentures. & In case of Dentures we will deliver dentures after
              customizing the denture teeth to match the colour and shape of
              your existing teeth it and adjust so they function in bite as your
              natural teeth.
            </li>
          </ul>
          <ul className="icon-cont">
            <li className="icon-img">
              <img src={icon4} className="icon" />
            </li>
            <li className="cont1">
              Dental implants are the best way to replace missing teeth.. This
              medical-grade implant is made of strong and resistant
              biocompatible material and takes the place of your tooth’s
              root.This is than used as a foundation on which we can place caps
              or use them as anchors for implant dentures
            </li>
          </ul>
        </div>
      </div>
      <div className="process-btn">
        <button
          onClick={() =>
            window.open("https://api.whatsapp.com/send?phone=919892227839")
          }
        >
          Appointment&nbsp; <i class="fab fa-whatsapp"></i>
        </button>
      </div>
      <div className="process">
        <h2>
          Dr. Bhatt answers your most common questions about dental Implant or
          Denture
        </h2>
      </div>
      <div className="accordion">
        <div className={isActive ? "contentBx" : "contentBx active"}>
          <div className="label" onClick={toggleClass}>
            What Are Dental Implants ?
          </div>
          <div className="content">
            <p>
              Dental implants are the best way to replace missing teeth.. This
              medical-grade implant is made of strong and resistant
              biocompatible material and takes the place of your tooth’s
              root.This is than used as a foundation on which we can place caps
              or use them as anchors for implant dentures.
            </p>
          </div>
        </div>
        <div
          className={isActive1 ? "contentBx" : "contentBx active"}
          // onClick={() => {
          //   onAccor();
          // }}
        >
          <div className="label" onClick={toggleClass1}>
            What Are Benefits of Dental Implants ?
          </div>
          <div className="content">
            <p>
              Strengthens your jaw bone
              <br />
              Promotes healthy gums
              <br />
              Restores your ability to chew and speak
              <br />
              Gives you the confidence to smile naturally again
            </p>
          </div>
        </div>
        <div className={isActive2 ? "contentBx" : "contentBx active"}>
          <div className="label" onClick={toggleClass2}>
            How Much Do Dental Implants Cost?
          </div>
          <div className="content">
            <p>
              The cost of the procedure varies based on Individual factors such
              as how many teeth a patient would like to be replaced, where they
              are located in the mouth, and what kind of restoration is used.
              Our Office after Examining you will explain you all your options
              to help you make an informed decision. An Approximate cost for a
              single Implant generally is in the Range of 20-25 thousand Indian
              Rupees.
            </p>
          </div>
        </div>
        <div className={isActive3 ? "contentBx" : "contentBx active"}>
          <div className="label" onClick={toggleClass3}>
            Are there any Payment Plans for Implant Treatments ?
          </div>
          <div className="content">
            <p>
              While it’s true that implant restorations tend to cost more than
              other traditional options, they are actually the best financial
              choice long-term In addition to their superior function and
              aesthetics, implants also last much, much longer than other
              restorations saving a lot of time and money over the years <br />
              You Might Want to Consider our{" "}
              <span
                onClick={() => {
                  window.open("/plan");
                }}
              >
                {" "}
                Save My Smile plan{" "}
              </span>{" "}
              for Easy Financing options for Your Implant treatments !
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
