import React from "react";
import Header from "../About US/aboutHeader/header";
import Profile from "../About US/dentist-Profile/profile";

export default function aboutUs() {
  return (
    <div id="about">
      <Header />
      <Profile />
    </div>
  );
}
