import React from "react";
import Header from "../Home/Header/header";
import Profile from "../Home/Profile/profile";
import Services from "../Home/Services/service";
import Footer from "../Home/footer/Footer";
import "./Home.css";

function Home() {
  return (
    <>
      <Header />
      <Profile />
      <Services />
    </>
  );
}
export default Home;
