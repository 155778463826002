import React, { useState } from "react";
import "../ServicesPage/rootcannal.css";

import icon from "../Image/Vector.png";
import icon2 from "../Image/Vector2.png";
import icon3 from "../Image/Vector3.png";
import icon4 from "../Image/Vector4.png";

export default function Rootcannal() {
  // function onAccor() {
  //   const accordion = document.getElementsByClassName("contentBx");
  //   // for (var i = 0; i < accordion.length; i++) {
  //   // accordion.addEventListener("click", function () {
  //   //   this.classList.toggle("active");
  //   // });
  //   // // }
  //   accordion.classList.toggle("active");
  // }
  const [isActive, setActive] = useState("false");
  const [isActive1, setActive1] = useState("false");
  const [isActive2, setActive2] = useState("false");
  const [isActive3, setActive3] = useState("false");

  const toggleClass = () => {
    setActive(!isActive);
  };
  const toggleClass1 = () => {
    setActive1(!isActive1);
  };
  const toggleClass2 = () => {
    setActive2(!isActive2);
  };
  const toggleClass3 = () => {
    setActive3(!isActive3);
  };
  return (
    <>
      <div className="aboutHeader">
        <div className="aboutbg">
          <h1> Teeth Removal Dentist in Mumbai</h1>
          <button
            className="schedule"
            onClick={() =>
              window.open("https://api.whatsapp.com/send?phone=919892227839")
            }
          >
            Scedule Your Visit &nbsp; <i class="fab fa-whatsapp"></i>
          </button>
        </div>
      </div>
      <div className="process">
        <h2>What to expect during Teeth Removal at Bhatt Dental</h2>
      </div>
      <div className="main-process">
        <div className="process-cont">
          {/* <div className="cont"> */}
          <ul className="icon-cont">
            <li className="icon-img">
              <img src={icon} className="icon" />
            </li>
            <li className="cont">
              Dr Bhatt will examine you Evaluate your Xrays and Explain you the
              need for removing your tooth and any alternative options if
              available to save the tooth. He will further explain you your
              options to replace the Extracted tooth.
            </li>
          </ul>
          <ul className="icon-cont">
            <li className="icon-img">
              <img src={icon2} className="icon" />
            </li>
            <li className="cont">
              Once the decision is agreed upon to remove the tooth Dr Bhatt will
              first Gently Numb the area and help you feel comfortable and at
              ease throughout the procedure.
            </li>
          </ul>
        </div>
        <div className="process-cont1">
          <ul className="icon-cont">
            <li className="icon-img">
              <img src={icon3} className="icon" />
            </li>
            <li className="cont1">
              After the Procedure Dr Bhatt Will explain you all the Precautions
              to be taken to care for the wound and Things that you need to
              avoid while the would heals .
            </li>
          </ul>
          <ul className="icon-cont">
            <li className="icon-img">
              <img src={icon4} className="icon" />
            </li>
            <li className="cont1">
              A Follow-up Appointment will be schedule to Check on you and too
              see the progress of wound healing. . He’ll also talk to you about
              high-tech restoration options so you can speak, eat, and smile
              with confidence. 
            </li>
          </ul>
        </div>
      </div>
      <div className="process-btn">
        <button
          onClick={() =>
            window.open("https://api.whatsapp.com/send?phone=919892227839")
          }
        >
          Appointment&nbsp; <i class="fab fa-whatsapp"></i>
        </button>
      </div>
      <div className="process">
        <h2>
          Dr. Bhatt answers your most common questions about Teeth Removal
        </h2>
      </div>
      <div className="accordion">
        <div className={isActive ? "contentBx" : "contentBx active"}>
          <div className="label" onClick={toggleClass}>
            Is Tooth Extractions the Only Options?
          </div>
          <div className="content">
            <p>
              If a tooth is severely decayed or is broken in an accident, a
              tooth extraction may be required. But Dr. Bhatt will only remove a
              tooth when absolutely necessary. All Possible measures will be
              taken to help you save your tooth. Often Impacted Wisdom teeth
              need to be removed to relieve you of pain and prevent further
              damage to adjacent tooth.
            </p>
          </div>
        </div>
        <div
          className={isActive1 ? "contentBx" : "contentBx active"}
          // onClick={() => {
          //   onAccor();
          // }}
        >
          <div className="label" onClick={toggleClass1}>
            What are the precautions I need to follow after tooth Extraction?
          </div>
          <div className="content">
            <p>
              Bite firmly on on gauze for 30-40 mins Have soft and cold food
              Take Painkillers and Medicines as prescribed Avoid Hot food and
              drinks Avoid straws to drink Avoid frequent spitting and rinsing
              Avoid Smoking and drinking alcohol
            </p>
          </div>
        </div>
        <div className={isActive2 ? "contentBx" : "contentBx active"}>
          <div className="label" onClick={toggleClass2}>
            Should Teeth Be Replaced after extractions?
          </div>
          <div className="content">
            <p>
              Yes all teeth except wisdom teeth need to be replaced after
              extractions. Replacing missing teeth is important to prevent
              excessive load & drifting of adjacent teeth in empty space caused
              due to extractions. Such Drifting can cause unnatural forces on
              adjacent teeth which can lead to increased wear not to forget
              additional biting load on adjacent teeth .
            </p>
          </div>
        </div>
        <div className={isActive3 ? "contentBx" : "contentBx active"}>
          <div className="label" onClick={toggleClass3}>
            What Are the Options for replacing extracted tooth?
          </div>
          <div className="content">
            <p>
              There are many options like Dental Implants Dental Bridges or even
              Partial Dentures to replace missing teeth. Our office will explain
              you all your options to help you decide what is best for you.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
