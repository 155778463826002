import React from "react";
import "./service.css";
import { Link } from "react-router-dom";

const services = () => {
  function str() {
    alert("Darshan");
  }
  const openNewPage = (url) => {
    const newWindow = window.open(url, "_self", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  return (
    <>
      <div className="service-cont" id="service">
        <div className="service-h2">
          <h2>Our Services</h2>
        </div>
        <div className="service-flex">
          <div
            className="service1"
            onClick={() => {
              openNewPage("./service/preventine-care");
            }}
          >
            <h1>Preventive Dental Care</h1>
          </div>
          <div
            className="service2"
            onClick={() => {
              openNewPage("./service/rootcannal");
            }}
          >
            <h1> Root Canals </h1>
          </div>
          <div
            className="service3"
            onClick={() => {
              openNewPage("./service/crowns");
            }}
          >
            <h1>Dental Crowns and Bridges </h1>
          </div>
        </div>
        <div className="service-flex">
          <div
            className="service4"
            onClick={() => {
              openNewPage("./service/dentalfilling");
            }}
          >
            <h1>Dental Fillings</h1>
          </div>
          <div
            className="service5"
            onClick={() => {
              openNewPage("./service/smiledesing");
            }}
          >
            <h1> Smile Design Esthetic Dentistry</h1>
          </div>
          <div
            className="service6"
            onClick={() => {
              openNewPage("/service/implants&dentures");
            }}
          >
            <h1> Implants and Dentures</h1>
          </div>
        </div>
        <div className="service-flex">
          <div
            className="service7"
            onClick={() => {
              openNewPage("./service/teethremoval");
            }}
          >
            <h1>Wisdom Teeth Removal</h1>
          </div>
          <div
            className="service8"
            onClick={() => {
              openNewPage("./service/fullmouthrehabs");
            }}
          >
            <h1>Full Mouth Rehabilitation</h1>
          </div>
          <div
            className="service9"
            onClick={() => {
              openNewPage("./service/emergency");
            }}
          >
            <h1>Emergency Dental Care</h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default services;
