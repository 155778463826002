import React from "react";
import "./profile.css";
import ProfileImg from "../../Image/vishal-bhatt.jpg";
import assistantDental from "../../Image/assistant-dental.jpeg";
import drDeepak from "../../Image/drDeepak.jpg";
import drKartikw from "../../Image/DrKartik.jfif";

import drshetkar from "../../Image/Dr.Deepak.jpg";
import * as ReactBoostrap from "react-bootstrap";
import { Link } from "react-router-dom";

function Profile() {
  return (
    <>
      <div className="profile-sec" id="profile">
        <div className="profile-img">
          <img src={ProfileImg} />
        </div>
        <div className="profile-cont">
          <h1>Dr. Vishal Bhatt</h1>
          <p className="p-cont">
            Dr. Bhatt is the Resident Dentist & Proprietor @ Bhatt Dental . He
            Graduated from Dental School in 2007. He Did his Dental Course from
            Yerla Dental College, Navi Mumbai & Internship From Prestigious Govt
            Dental College Mumbai.Since his graduation Dr. Bhatt has
            continuously strived to upgrade his skills with Various Continuing
            Dental Education Courses.
          </p>
          <p className="p1-cont">
            Dr. Bhatt believes in empowering patients with the Knowledge and
            Education so they can be empowered to take control of their Dental
            Health.
          </p>
          <p className="p1-cont">
            A considerable time and effort is spent with each of his patients
            understanding their needs and utmost care is taken deliver care
            which is personalized to each Patients needs, Something which his
            patients vouch for.
          </p>
          {/* <Link to="/">
            <button className="appointment  about-btn">Know More</button>
          </Link> */}
        </div>
      </div>
      <div className="team">
        <div className="team-hadline">
          <h2>Team</h2>
        </div>
        <div className="card-profile">
          <div class="card">
            <img src={assistantDental} alt="Avatar" className="img" />
            <div class="container">
              <h2>
                <b>Kunika Gaurav</b>
              </h2>
              <p>Dental Assistant</p>
            </div>
          </div>
          <div class="card">
            <img src={drKartikw} alt="Avatar" className="img" />
            <div class="container">
              <h2>
                <b>Dr Karthik Balasubramaniam </b>
              </h2>
              <p>Periodontist</p>
            </div>
          </div>
          <div class="card">
            <img src={drDeepak} alt="Avatar" className="img" />
            <div class="container">
              <h2>
                <b>Dr.Deepak Jagtap</b>
              </h2>
              <p>Oral Surgeon</p>
            </div>
          </div>
          <div class="card">
            <img src={drshetkar} alt="Avatar" className="img" />
            <div class="container">
              <h2>
                <b>DR. YASH K SHEKATKAR</b>
              </h2>
              <p>Orthodontist</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Profile;
