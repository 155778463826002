import React from "react";
import "./Button.css";
import { Link } from "react-router-dom";

export function Button() {
  const openNewPage = (url) => {
    const newWindow = window.open(url, "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  return (
    <button
      className="btn"
      onClick={() => {
        openNewPage("https://pages.razorpay.com/bhattDental");
      }}
    >
      Payment
    </button>
  );
}
